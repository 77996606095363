import SaasHeader from 'pwasaasComponent/SaasHeader/SaasHeader.component';

import './SaasHeader.override.manual.style';

/** @namespace Koffiecornerhetstoom/Component/SaasHeader/Component/SaasHeaderComponent */
export class SaasHeaderComponent extends SaasHeader {
    /** TODO: Add setting to enable/disable mobile usps?? */
    renderMobileHeaderUsp() {
        return null;
    }

    renderHeaderBottom() {
        const { device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return super.renderHeaderBottom();
    }
}

export default SaasHeaderComponent;
